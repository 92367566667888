import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useLazyViewUserPlantDailyPowerQuery } from "../../../redux/api/generation/generationAPI";
import IViewUserSolarPlantPowerResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-user-solar-plant-power-response-dto";
import BatteryInfoCard from "../../../shared/oversight-core/shared-components/battery-info-card/battery-info-card";
import SolarInfoCard from "../../../shared/oversight-core/shared-components/solar-info-card/solar-info-card";
import AreaChart from "../../../shared/oversight-core/ui-elements/area-chart/area-chart";
import ArrowButton from "../../../shared/oversight-core/ui-elements/arrow-button/arrow-button";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";

interface ChartData {
  batteryLevel: number[];
  labels: string[];
}

const defaultChartDataValues = {
  batteryLevel: [],
  labels: [],
};

const BatteryInformation = () => {
  const { state } = useLocation();

  const navigate = useNavigate();

  const [chartData, setChartData] = useState<ChartData>({
    ...defaultChartDataValues,
  });

  const [triggerDailyPlantPower, { isFetching: isFetchingDailyPlantPower }] =
    useLazyViewUserPlantDailyPowerQuery();

  useEffect(() => {
    if (state?.solarPlant?.plantId) {
      triggerDailyPlantPower({
        plantId: state?.solarPlant?.plantId,
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate(),
      })
        .unwrap()
        .then((res: IViewUserSolarPlantPowerResponseDTO) => {
          const battery = res.solarPlantPowerView.batterLevel;

          setChartData({
            batteryLevel: battery.map((b) => b.level),
            labels: battery.map((b) => moment(b.date).utc().format("HH:mm")),
          });
        })
        .catch(() => {
          setChartData({ ...defaultChartDataValues });
        });
    }
  }, [triggerDailyPlantPower, state?.solarPlant?.plantId]);

  return (
    <>
      <Row className="mx-0">
        <Col>
          <ArrowButton onBack={() => navigate(-1)} />
        </Col>
      </Row>
      <Row className="mt-1 g-4">
        <Col className="col-12 col-lg-4">
          <BatteryInfoCard
            value={state?.solarPlant?.batterySoc}
            batteryCapacity={state?.solarPlant?.batteryCapacity}
          />
        </Col>
        <Col className="col-12 col-sm-6 col-lg-4 col-xxl">
          <SolarInfoCard
            title="Charging From"
            value={"-"}
            icon="bolt"
            iconColor="#2F3E71"
            backgroundColor="#EEEEF8"
            isSwapTitle
          />
        </Col>
        <Col className="col-12 col-sm-6 col-lg-4 col-xxl">
          <SolarInfoCard
            title="Usage Available"
            value="-"
            icon="av_timer"
            iconColor="#A4635D"
            backgroundColor="#F8F3EE"
          />
        </Col>
        <Col className="col-12 col-sm-6 col-lg-6 col-xxl">
          <SolarInfoCard
            title="Average Daily Use"
            value="-"
            icon="electric_meter"
            iconColor="#345B7A"
            backgroundColor="#EFF6FC"
          />
        </Col>
        <Col className="col-12 col-sm-6 col-lg-6 col-xxl">
          <SolarInfoCard
            title="Saving Due to Battery "
            value="-"
            icon="energy_savings_leaf"
            iconColor="#32A851"
            backgroundColor="#F3F8EE"
          />
        </Col>
      </Row>
      <div className="container-white position-relative mt-4">
        <AreaChart
          borderColor1="#5030B0"
          backgroundColor1="#4C29CC17"
          label1="Battery Level"
          yAxesUnit="%"
          labels={chartData.labels}
          data1={chartData.batteryLevel}
          isChartDataAvailable={chartData.batteryLevel.length > 0}
        />
        <SpinnerModal show={isFetchingDailyPlantPower} />
      </div>
    </>
  );
};

export default BatteryInformation;
