import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { IMapLocation } from "../../interfaces/map-location";
import AppButton from "../../ui-elements/buttons/app-button/app-button";
import MaterialIcon from "../../ui-elements/material-icon/material-icon";
import { showErrorMessage } from "../../utils/toast";
import styles from "./google-map-modal.module.scss";

interface IProps {
  show: boolean;
  onClose: () => void;
  setSelectedLocationDetails: React.Dispatch<
    React.SetStateAction<IMapLocation>
  >;
  selectedLocationDetails: IMapLocation;
}

const initialCenter = {
  lat: 6.9271,
  lng: 79.8612,
};

const GoogleMapModal = (props: IProps) => {
  const { show, onClose, setSelectedLocationDetails, selectedLocationDetails } =
    props;

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBQluIJBjDXSHfS9C5nNW24hw_i5uwE0Xg",
    libraries: ["places"],
  });

  const [mapCenter, setMapCenter] = useState(initialCenter);
  const [locationDetails, setLocationDetails] = useState<IMapLocation>({
    name: "",
    lat: 0,
    lng: 0,
  });
  const [containerHeight, setContainerHeight] = useState(470);
  const [searchQuery, setSearchQuery] = useState("");
  const [autocompleteResults, setAutocompleteResults] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);
  // const [searchBox, setSearchBox] =
  //   useState<google.maps.places.SearchBox | null>(null);

  const containerStyle = {
    width: "100%",
    height: `${containerHeight}px`,
  };

  useEffect(() => {
    const updateHeight = () => {
      if (window.innerWidth < 501) {
        setContainerHeight(515);
      } else {
        setContainerHeight(560);
      }
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  useEffect(() => {
    if (
      selectedLocationDetails &&
      selectedLocationDetails?.lat &&
      selectedLocationDetails?.lng &&
      selectedLocationDetails?.name
    ) {
      setLocationDetails(selectedLocationDetails);
      setMapCenter({
        lat: selectedLocationDetails.lat,
        lng: selectedLocationDetails.lng,
      });
      setSearchQuery(selectedLocationDetails.name);
    } else {
      setMapCenter(initialCenter);
      setSearchQuery("");
    }
  }, [selectedLocationDetails]);

  useEffect(() => {
    if (locationDetails) {
      setSearchQuery(locationDetails.name);
    }
  }, [locationDetails]);

  const handleMapClick = async (event: google.maps.MapMouseEvent) => {
    if (event.latLng) {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();

      const geocoder = new google.maps.Geocoder();
      const location = { lat, lng };

      geocoder.geocode({ location }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results && results[0]) {
          const address = results[0].formatted_address;

          setLocationDetails({
            name: address,
            lat,
            lng,
          });

          setMapCenter({ lat, lng });
        } else {
          showErrorMessage("Geocoder failed due to: " + status);
        }
      });
    } else {
      showErrorMessage("Click location is invalid");
    }
  };

  // const onSearchBoxLoad = (searchBoxInstance: google.maps.places.SearchBox) => {
  //   setSearchBox(searchBoxInstance);
  // };

  // const onPlacesChanged = () => {
  //   const places = searchBox?.getPlaces();
  //   if (places && places.length > 0) {
  //     const place = places[0]; // Get the first place suggestion
  //     if (place.geometry?.location) {
  //       const lat = place.geometry.location.lat();
  //       const lng = place.geometry.location.lng();
  //       setLocationDetails({
  //         address: place.formatted_address || "",
  //         latitude: lat,
  //         longitude: lng,
  //       });
  //       setMapCenter({ lat, lng });
  //     }
  //   }
  // };

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchQuery(value);

    if (!value) {
      setAutocompleteResults([]);
      return;
    }

    const service = new google.maps.places.AutocompleteService();
    service.getPlacePredictions({ input: value }, (predictions, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
        setAutocompleteResults(predictions);
      }
    });
  };

  const handleAutocompleteClick = (
    place: google.maps.places.AutocompletePrediction
  ) => {
    const service = new google.maps.places.PlacesService(
      document.createElement("div")
    );
    service.getDetails({ placeId: place.place_id }, (result, status) => {
      if (
        status === google.maps.places.PlacesServiceStatus.OK &&
        result &&
        result.geometry &&
        result.geometry.location
      ) {
        const lat = result.geometry.location.lat();
        const lng = result.geometry.location.lng();
        setLocationDetails({
          name: result.formatted_address || "",
          lat,
          lng,
        });
        setMapCenter({ lat, lng });
        setAutocompleteResults([]);
        setSearchQuery(result.formatted_address || "");
      }
    });
  };

  return (
    <>
      {show && (
        <div className={styles.googleMapModalContainer}>
          {isLoaded && (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={mapCenter}
              zoom={10}
              onClick={handleMapClick}
            >
              {/* <StandaloneSearchBox
                onLoad={onSearchBoxLoad}
                onPlacesChanged={onPlacesChanged}
              > */}
              <input
                type="text"
                placeholder="Search Place"
                className={styles[`input-style`]}
                value={searchQuery}
                onChange={handleInputChange}
              />
              {/* </StandaloneSearchBox> */}
              <Marker position={mapCenter} />
            </GoogleMap>
          )}
          {autocompleteResults.length > 0 && (
            <ul className={styles.autocompleteList}>
              {autocompleteResults.map((result) => (
                <li
                  key={result.place_id}
                  onClick={() => handleAutocompleteClick(result)}
                  className={styles.autocompleteItem}
                >
                  <div className="d-flex gap-1">
                    <div>
                      <MaterialIcon
                        icon="location_on"
                        size={10}
                        className="mt-1"
                      />
                    </div>
                    <div>{result.description}</div>
                  </div>
                </li>
              ))}
            </ul>
          )}
          <Row className="row-cols-auto mt-4 mx-2">
            <Col>
              <AppButton
                text="Confirm"
                onClick={() => {
                  setSelectedLocationDetails(locationDetails);
                  onClose();
                }}
                type="button"
              />
            </Col>
            <Col>
              <AppButton
                text="Cancel"
                onClick={() => {
                  if (!selectedLocationDetails) {
                    setMapCenter(initialCenter);
                    setSearchQuery("");
                  }
                  setAutocompleteResults([]);
                  onClose();
                }}
                type="button"
                variant="transparent"
              />
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default GoogleMapModal;
