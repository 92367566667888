import { Col, Row } from "react-bootstrap";
import styles from "./indicator-tabs.module.scss";

interface ITabs {
  indicatorColor: string;
  name: string;
  id: number;
  selectedColor: string;
}

interface IProps {
  tabs: ITabs[];
  onChange: (id: number) => void;
  selectedTabId: number;
}

const IndicatorTabs = (props: IProps) => {
  const { tabs, onChange, selectedTabId } = props;
  return (
    <Row className="mx-0 gap-3">
      {tabs.map((i) => {
        return (
          <Col
            className={`col-auto ${styles.tabContainer} ${
              selectedTabId === i.id && styles[i.selectedColor]
            } cursor-pointer py-2 px-4`}
            onClick={() => {
              onChange(i.id);
            }}
          >
            <Row className="align-items-center">
              <Col className={`col-auto pe-0 ps-0`}>
                <div
                  className={`${styles.indicator} ${
                    styles[`${i.indicatorColor}`]
                  }`}
                ></div>
              </Col>
              <Col
                className={`${
                  selectedTabId === i.id
                    ? `text-dark font-weight-500`
                    : `text-light font-weight-400`
                } font-size-14 ps-2`}
              >
                {i.name}
              </Col>
            </Row>
          </Col>
        );
      })}
    </Row>
  );
};

export default IndicatorTabs;
