import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import DeviceCardWithToggleButton from "../../../../shared/components/device-card-with-toggle-button/device-card-with-toggle-button";
import { EDemandControlActionStatus } from "../../../../shared/oversight-core/enums/demand-control-action-status";
import { EPowerMeasureType } from "../../../../shared/oversight-core/enums/power-measure-type";
import { IDemandControlAction } from "../../../../shared/oversight-core/interfaces/demand-control-action";
import { IDemandControlActionPowerConsumerView } from "../../../../shared/oversight-core/interfaces/demand-control-action-power-consumer-view";
import AppButton from "../../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import { powerConvertor } from "../../../../shared/oversight-core/utils/power-convertor";
import AccountActivateDeactivateConfirmModal from "../account-activate-deactivate-confirm-modal/account-activate-deactivate-confirm-modal";
import PowerConsumerDeactivateConfirmModal from "../power-consumer-deactivate-confirm-modal/power-consumer-deactivate-confirm-modal";
import styles from "./view-user-device-modal.module.scss";

interface IProps extends ModelContainerProps {
  show: boolean;
  demandControlActionId: string;
  viewSpaceClusterInDemandControlAction: IDemandControlAction | undefined;
  demandControlActionStatus: EDemandControlActionStatus;
  updatePowerConsumer: (
    demandControlActionId: string,
    spaceClusterId: string,
    powerConsumerId: string
  ) => void;
  updateSpaceCluster: (
    demandControlActionId: string,
    spaceClusterId: string
  ) => void;
}

const ViewUserDevicesModal = (props: IProps) => {
  const {
    show,
    demandControlActionId,
    viewSpaceClusterInDemandControlAction,
    demandControlActionStatus,
    updatePowerConsumer,
    updateSpaceCluster,
    ...rest
  } = props;

  const [showConfirmSpaceClusterModal, setShowConfirmSpaceClusterModal] =
    useState(false);
  const [showConfirmPowerConsumerModal, setShowConfirmPowerConsumerModal] =
    useState(false);
  const [lowPriorityDevices, setLowPriorityDevices] = useState<
    IDemandControlActionPowerConsumerView[] | undefined
  >();
  const [highPriorityDevices, setHighPriorityDevices] = useState<
    IDemandControlActionPowerConsumerView[] | undefined
  >();
  const [powerConsumerId, setPowerConsumerId] = useState("");

  useEffect(() => {
    setLowPriorityDevices(
      viewSpaceClusterInDemandControlAction?.powerConsumers.filter(
        (powerConsumer) => [1].includes(powerConsumer.userPriority)
      )
    );
  }, [viewSpaceClusterInDemandControlAction?.powerConsumers]);

  useEffect(() => {
    setHighPriorityDevices(
      viewSpaceClusterInDemandControlAction?.powerConsumers.filter(
        (powerConsumer) => [0].includes(powerConsumer.userPriority)
      )
    );
  }, [viewSpaceClusterInDemandControlAction?.powerConsumers]);

  return (
    <>
      <ModalContainer
        title={
          <Row className="align-items-center">
            <Col>
              <Row>
                <Col className="text-dark font-size-16 font-weight-500">
                  {
                    viewSpaceClusterInDemandControlAction
                      ?.serviceProviderAccount.accountNumber
                  }
                </Col>
              </Row>
              <Row>
                <Col className="text-light font-size-12 font-weight-500">
                  {
                    viewSpaceClusterInDemandControlAction
                      ?.serviceProviderAccount.label
                  }
                </Col>
              </Row>
            </Col>
            <Col className="col-auto pe-0">
              <AppButton
                text={
                  viewSpaceClusterInDemandControlAction?.isRemoved
                    ? `Enable`
                    : `Disable`
                }
                iconName="swap_horiz"
                size="medium"
                onClick={() => {
                  if (
                    demandControlActionStatus ===
                    EDemandControlActionStatus.NOT_STARTED
                  ) {
                    setShowConfirmSpaceClusterModal(true);
                  }
                }}
                className={
                  demandControlActionStatus ===
                  EDemandControlActionStatus.NOT_STARTED
                    ? `cursor-pointer`
                    : `cursor-disabled`
                }
                variant={
                  viewSpaceClusterInDemandControlAction?.isRemoved
                    ? `blue`
                    : `gray`
                }
              />
            </Col>
          </Row>
        }
        {...rest}
        show={show}
        size="modal-lg"
        isButtonsVisible={false}
      >
        <>
          <Row className="align-items-center justify-content-between">
            <Col className="col-auto text-center">
              <Row>
                <Col className="text-light font-size-32 font-weight-600">
                  {powerConvertor(
                    viewSpaceClusterInDemandControlAction?.usualUsage || 0,
                    EPowerMeasureType.KILO_WATT
                  ).toFixed(2)}
                  kW
                </Col>
              </Row>
              <Row>
                <Col className="text-dark font-size-14 font-weight-400">
                  Usual Usage
                </Col>
              </Row>
            </Col>
            <Col className="col-auto text-center">
              <Row>
                <Col className="text-light font-size-32 font-weight-600">
                  {powerConvertor(
                    viewSpaceClusterInDemandControlAction?.reducedUsage || 0,
                    EPowerMeasureType.KILO_WATT
                  ).toFixed(2)}
                  kW
                </Col>
              </Row>
              <Row>
                <Col className="text-dark font-size-14 font-weight-400">
                  Reduced Usage
                </Col>
              </Row>
            </Col>
            <Col className="col-auto text-center">
              <Row>
                <Col className="text-light font-size-32 font-weight-600">
                  {powerConvertor(
                    viewSpaceClusterInDemandControlAction?.reduction || 0,
                    EPowerMeasureType.KILO_WATT
                  ).toFixed(2)}
                  kW
                </Col>
              </Row>
              <Row>
                <Col className="text-dark font-size-14 font-weight-400">
                  Saving
                </Col>
              </Row>
            </Col>
            {/* <Col className="col-auto text-center">
            <Row>
              <Col className="text-light font-size-32 font-weight-600">
                0.2kW
              </Col>
            </Row>
            <Row>
              <Col className="text-dark font-size-14 font-weight-400">
                Buffer
              </Col>
            </Row>
          </Col> */}
            <Col className="col-auto text-center">
              <Row>
                <Col className="text-light font-size-32 font-weight-600">
                  {viewSpaceClusterInDemandControlAction?.devices}
                </Col>
              </Row>
              <Row>
                <Col className="text-dark font-size-14 font-weight-400">
                  Devices
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="text-dark font-size-16 font-weight-500">
              Low Priority Devices
            </Col>
          </Row>
          {lowPriorityDevices && lowPriorityDevices?.length > 0 ? (
            <div className={`${styles.overflowDeviceList} mt-2`}>
              {lowPriorityDevices?.map((device, index) => (
                <Row key={index} className="mt-1">
                  <Col>
                    <DeviceCardWithToggleButton
                      powerConsumer={device}
                      onSwitch={() => {
                        if (
                          viewSpaceClusterInDemandControlAction?.id &&
                          !viewSpaceClusterInDemandControlAction?.isRemoved &&
                          demandControlActionStatus ===
                            EDemandControlActionStatus.NOT_STARTED
                        ) {
                          const selectedPowerConsumers =
                            viewSpaceClusterInDemandControlAction.powerConsumers.filter(
                              (pc) => pc.isSelected
                            );

                          if (
                            selectedPowerConsumers.length === 1 &&
                            device.isSelected
                          ) {
                            setPowerConsumerId(device.id);
                            setShowConfirmPowerConsumerModal(true);
                            return;
                          }
                          updatePowerConsumer(
                            demandControlActionId,
                            viewSpaceClusterInDemandControlAction?.id,
                            device.id
                          );
                        }
                      }}
                      disabled={
                        viewSpaceClusterInDemandControlAction?.isRemoved ||
                        demandControlActionStatus !==
                          EDemandControlActionStatus.NOT_STARTED
                      }
                    />
                  </Col>
                </Row>
              ))}
            </div>
          ) : (
            <div className="container-dash my-2 text-dark font-size-14 font-weight-400">
              There are no Low Priority Devices.
            </div>
          )}
          <Row className="mt-2">
            <Col className="text-dark font-size-16 font-weight-500">
              High Priority Devices
            </Col>
          </Row>
          {highPriorityDevices && highPriorityDevices.length > 0 ? (
            <div className={`${styles.overflowDeviceList} mt-2`}>
              {highPriorityDevices?.map((device, index) => (
                <Row key={index} className="mt-1">
                  <Col>
                    <DeviceCardWithToggleButton
                      powerConsumer={device}
                      onSwitch={() => {
                        if (
                          viewSpaceClusterInDemandControlAction?.id &&
                          !viewSpaceClusterInDemandControlAction?.isRemoved &&
                          demandControlActionStatus ===
                            EDemandControlActionStatus.NOT_STARTED
                        ) {
                          const selectedPowerConsumers =
                            viewSpaceClusterInDemandControlAction.powerConsumers.filter(
                              (pc) => pc.isSelected
                            );

                          if (
                            selectedPowerConsumers.length === 1 &&
                            device.isSelected
                          ) {
                            setPowerConsumerId(device.id);
                            setShowConfirmPowerConsumerModal(true);
                            return;
                          }

                          updatePowerConsumer(
                            demandControlActionId,
                            viewSpaceClusterInDemandControlAction?.id,
                            device.id
                          );
                        }
                      }}
                      disabled={
                        viewSpaceClusterInDemandControlAction?.isRemoved ||
                        demandControlActionStatus !==
                          EDemandControlActionStatus.NOT_STARTED
                      }
                    />
                  </Col>
                </Row>
              ))}
            </div>
          ) : (
            <div className="container-dash my-2 text-dark font-size-14 font-weight-400">
              There are no High Priority Devices.
            </div>
          )}
          {/* <Row className="mt-3">
            <Col className="text-center font-size-16 font-weight-400">
              <Link to={""}>View Other Devices</Link>
            </Col>
          </Row> */}
        </>
      </ModalContainer>
      <AccountActivateDeactivateConfirmModal
        show={showConfirmSpaceClusterModal}
        onClose={() => setShowConfirmSpaceClusterModal(false)}
        onCancel={() => setShowConfirmSpaceClusterModal(false)}
        onConfirm={() => {
          if (
            viewSpaceClusterInDemandControlAction?.id &&
            demandControlActionId
          ) {
            updateSpaceCluster(
              demandControlActionId,
              viewSpaceClusterInDemandControlAction?.id
            );
            setShowConfirmSpaceClusterModal(false);
          }
        }}
        spaceStatus={viewSpaceClusterInDemandControlAction?.isRemoved || false}
      />
      <PowerConsumerDeactivateConfirmModal
        show={showConfirmPowerConsumerModal}
        onClose={() => setShowConfirmPowerConsumerModal(false)}
        onCancel={() => setShowConfirmPowerConsumerModal(false)}
        onConfirm={() => {
          if (
            viewSpaceClusterInDemandControlAction?.id &&
            !viewSpaceClusterInDemandControlAction?.isRemoved &&
            demandControlActionStatus === EDemandControlActionStatus.NOT_STARTED
          ) {
            updatePowerConsumer(
              demandControlActionId,
              viewSpaceClusterInDemandControlAction?.id,
              powerConsumerId
            );
            setShowConfirmPowerConsumerModal(false);
          }
        }}
      />
    </>
  );
};

export default ViewUserDevicesModal;
