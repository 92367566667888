import { Card } from "react-bootstrap";
import { EConsumptionValueComparison } from "../../oversight-core/enums/consumption-comparison";
import MaterialIcon from "../../oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../oversight-core/ui-elements/spinner/spinner";
import styles from "./solar-info-statistic-card.module.scss";

interface IProps {
  title: string;
  value: number;
  unit?: string;
  currency?: boolean;
  percentage?: number;
  comparison?: EConsumptionValueComparison;
  isLoading?: boolean;
}

const SolarInfoStatisticCard = (props: IProps) => {
  const { title, value, unit, currency, percentage, comparison, isLoading } =
    props;

  return (
    <Card className={styles.card}>
      <Card.Body className="p-4">
        <div className={`mb-1 ${styles.title}`}>{title}</div>
        {isLoading ? (
          <SpinnerModal show={isLoading} withOverlay={false} size="md" />
        ) : (
          <>
            <div className={`${styles.value} mb-1`}>
              {currency && "Rs. "}
              {value}
              <span> {unit}</span>
            </div>
            <div className="d-flex align-items-center gap-1">
              {percentage && (
                <div className={styles.percentage}>{percentage}%</div>
              )}
              {comparison &&
                (comparison === EConsumptionValueComparison.HIGH ||
                  comparison === EConsumptionValueComparison.LOW) && (
                  <MaterialIcon
                    size={15}
                    icon="trending_up"
                    color={
                      comparison === EConsumptionValueComparison.HIGH
                        ? "#EF2F3A"
                        : "#4FC13C"
                    }
                    className="justify-content-center"
                  />
                )}
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default SolarInfoStatisticCard;
