import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import {
  useLazyViewUserPlantDailyPowerQuery,
  useLazyViewUserPlantDetailsQuery,
} from "../../../redux/api/generation/generationAPI";
import goodWeLogo from "../../../shared/oversight-core/assets/images/goodwe-logo.svg";
import IViewUserSolarPlantDetailsResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-user-solar-plant-details-response-dto";
import IViewUserSolarPlantPowerResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-user-solar-plant-power-response-dto";
import { EEnergySourceType } from "../../../shared/oversight-core/enums/energy-source-type";
import { AppRoute } from "../../../shared/oversight-core/interfaces/app-routes";
import { ISolarPlantView } from "../../../shared/oversight-core/interfaces/solar-plant-view";
import GenerationInfoCard from "../../../shared/oversight-core/shared-components/generation-info-card/generation-info-card";
import ActiveInactiveIndicator from "../../../shared/oversight-core/ui-elements/active-inactive-indicator/active-inactive-indicator";
import AreaChart from "../../../shared/oversight-core/ui-elements/area-chart/area-chart";
import ButtonWithBadge from "../../../shared/oversight-core/ui-elements/buttons/button-with-badge/button-with-badge";
import EnergySourceInfo from "../../../shared/oversight-core/ui-elements/energy-source-info/energy-source-info";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import Helper from "../../../shared/oversight-core/utils/helpers";
import styles from "./site-information.module.scss";

interface ChartData {
  values: number[];
  labels: string[];
}

const defaultChartDataValues = {
  values: [],
  labels: [],
};

const SiteInformation = () => {
  const { state } = useLocation();
  const solar = state?.solar;

  const [solarPlant, setSolarPlant] = useState<ISolarPlantView>();
  const [isFirstTimeLoading, setIsFirstTimeLoading] = useState(true);
  const [solarPowerData, setSolarPowerData] = useState<ChartData>({
    ...defaultChartDataValues,
  });
  const [batteryPowerData, setBatteryPowerData] = useState<ChartData>({
    ...defaultChartDataValues,
  });

  const navigate = useNavigate();

  const [triggerUserPlantDetails, { isFetching: isFetchingPlantDetails }] =
    useLazyViewUserPlantDetailsQuery();
  const [triggerDailyPlantPower, { isFetching: isFetchingDailyPlantPower }] =
    useLazyViewUserPlantDailyPowerQuery();

  useEffect(() => {
    if (solar?.plantId) {
      triggerUserPlantDetails({ plantId: solar.plantId })
        .unwrap()
        .then((res: IViewUserSolarPlantDetailsResponseDTO) => {
          setSolarPlant(res.solarPlantView);
          setIsFirstTimeLoading(false);
        })
        .catch(() => {
          console.log("Failed to fetch plant details");
        });
    }
  }, [triggerUserPlantDetails, solar?.plantId]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (solar?.plantId) {
        triggerUserPlantDetails({ plantId: solar.plantId })
          .unwrap()
          .then((res: IViewUserSolarPlantDetailsResponseDTO) => {
            setSolarPlant(res.solarPlantView);
            setIsFirstTimeLoading(false);
          })
          .catch(() => {
            console.log("Failed to fetch plant details");
          });
      }
    }, 4000);
    return () => clearInterval(interval);
  }, [triggerUserPlantDetails, solar?.plantId]);

  useEffect(() => {
    if (solar?.plantId) {
      triggerDailyPlantPower({
        plantId: solar.plantId,
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate(),
      })
        .unwrap()
        .then((res: IViewUserSolarPlantPowerResponseDTO) => {
          const power = res.solarPlantPowerView.solarPower;
          const battery = res.solarPlantPowerView.batterPower;

          setSolarPowerData({
            values: power.map((p) => p.power / 1000),
            labels: power.map((p) => moment(p.date).utc().format("HH:mm")),
          });

          setBatteryPowerData({
            values: battery.map((p) => p.power / 1000),
            labels: battery.map((p) => moment(p.date).utc().format("HH:mm")),
          });
        })
        .catch(() => {
          setSolarPowerData({ ...defaultChartDataValues });
          setBatteryPowerData({ ...defaultChartDataValues });
        });
    }
  }, [triggerDailyPlantPower, solar?.plantId]);

  return (
    <div className="position-relative">
      <div className="container-white">
        <Row className="align-items-center justify-content-between gy-3">
          <Col className="col-auto">
            <Row className="align-items-center gy-3">
              <Col className="col-auto">
                <Row>
                  <Col className="text-dark font-weight-500 font-size-14">
                    {solar?.plantName}
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col
                    className={`col-auto text-light font-weight-400 font-size-12 pe-0 ${styles.textTruncate}`}
                    id={`location-${solarPlant?.plantId}`}
                  >
                    {solar?.address}
                  </Col>
                  <Col className="ps-2">
                    <ActiveInactiveIndicator isActive={solar?.isConnected} />
                  </Col>
                  <Tooltip
                    offset={5}
                    anchorSelect={`#location-${solarPlant?.plantId}`}
                    content={solarPlant?.address}
                    className={styles.toolTip}
                    classNameArrow={styles.toolTipArrow}
                  />
                </Row>
              </Col>
              <Col>
                <img src={goodWeLogo} alt="" />
              </Col>
            </Row>
          </Col>
          <Col className="col-auto">
            <Row className="align-items-center">
              <Col className="col-auto">
                <Row>
                  <Col className="text-dark font-weight-500 font-size-14 mb-2">
                    Total Generation
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <EnergySourceInfo
                      title="Solar"
                      value={Number(Helper.roundTo2(solar?.totalGeneration))}
                      icon="solar_power"
                      isFetching={false}
                    />
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row className="align-items-center">
                  <Col className="col-auto text-primary font-size-12 font-weight-500 pe-0">
                    June 2024
                  </Col>
                  <Col>
                    <ButtonWithBadge
                      text="Filter"
                      icon="filter_alt"
                      onClick={() => {}}
                      badgeValue={0}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Row className="align-items-center mt-4 mb-2">
        <Col className="col-auto pe-0">
          <ActiveInactiveIndicator isLive={true} />
        </Col>
        <Col className="text-dark font-weight-500 font-size-14">
          Live Generation
        </Col>
      </Row>
      <Row className="gy-4">
        <Col className="col-12 col-sm-6 col-md-12 col-lg-6 col-xxl">
          <GenerationInfoCard
            powerGeneratorType={EEnergySourceType.SOLAR_PV}
            powerGenerationValue={Number(
              Helper.roundTo2((solarPlant?.pac ?? 0) / 1000)
            )}
            powerGenerationCapacity={solarPlant?.pvCapacity}
            energyGenerationValue={0}
            onClick={() =>
              navigate(
                AppRoute.ADMIN_SOLAR_DASHBOARD + "/" + solarPlant?.plantId,
                {
                  state: { plantId: solarPlant?.plantId },
                }
              )
            }
          />
        </Col>
        {solarPlant?.isStored && (
          <Col className="col-12 col-sm-6 col-md-12 col-lg-6 col-xxl">
            <GenerationInfoCard
              powerGeneratorType={EEnergySourceType.BATTERY}
              powerGenerationValue={solarPlant?.batterySoc || 0}
              powerGenerationCapacity={solarPlant?.batteryCapacity}
              energyGenerationValue={0}
              onClick={() =>
                navigate(
                  AppRoute.ADMIN_BATTERY_DASHBOARD + "/" + solarPlant?.plantId,
                  {
                    state: { solarPlant },
                  }
                )
              }
              batteryStatus={Number(solarPlant?.batteryStatus)}
            />
          </Col>
        )}
      </Row>
      <div className="container-white position-relative mt-4">
        <AreaChart
          borderColor1="#D79C2C"
          borderColor2="#5E45B8"
          backgroundColor1="#FCFDF7"
          backgroundColor2="#F5F4FA"
          label1="Solar Power"
          label2="Battery Charged"
          yAxesUnit="kW"
          labels={solarPowerData.labels}
          data1={solarPowerData.values}
          data2={batteryPowerData.values}
          hasSecondDataSet={true}
          isChartDataAvailable={true}
          xAxisLabel="Hours"
        />
        <SpinnerModal show={isFetchingDailyPlantPower} />
      </div>
      <SpinnerModal show={isFetchingPlantDetails && isFirstTimeLoading} />
    </div>
  );
};

export default SiteInformation;
