import { useEffect, useState } from "react";
import { useLazyViewGenerationServiceProvidersQuery } from "../../../redux/api/generation/generationAPI";
import IViewAllGenerationServiceProvidersResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-all-generation-service-providers-response-dto";
import { EDistricts } from "../../../shared/oversight-core/enums/districts";
import { ESolarPlantSize } from "../../../shared/oversight-core/enums/solar-plant-size";
import { IGenerationServiceProviderView } from "../../../shared/oversight-core/interfaces/generation-service-provider-view";
import FilteringSidePanel from "../../../shared/oversight-core/shared-components/filtering-side-panel/filtering-side-panel";
import AppSelect, {
  Option,
} from "../../../shared/oversight-core/ui-elements/app-select/app-select";
import getDistrict from "../../../shared/oversight-core/utils/get-district";
import { IExtendedSearchFilters } from "../sites";

interface IProps {
  show: boolean;
  onClose: () => void;
  updateFilters: (
    district: EDistricts | string,
    generationServiceProviderId: string,
    solarPlantSize: ESolarPlantSize | string
  ) => void;
  setFilterBadgeValue: React.Dispatch<React.SetStateAction<number>>;
  filters: IExtendedSearchFilters;
}

const emptyOption: Option = {
  value: "",
  label: "",
};

const SitesFilteringSidePanel = (props: IProps) => {
  const { show, filters, onClose, updateFilters, setFilterBadgeValue } = props;

  const sizeOption: Option[] = [
    { value: "", label: "" },
    { value: ESolarPlantSize.SMALL, label: "0 - 1Kw" },
    { value: ESolarPlantSize.MEDIUM, label: "10kW - 50kW" },
    { value: ESolarPlantSize.LARGE, label: "50kW - 100kW" },
    { value: ESolarPlantSize.EXTRA_LARGE, label: "Greater than 100kW" },
  ];

  const [selectedDistrict, setSelectedDistrict] = useState<Option>({
    ...emptyOption,
  });
  const [selectedSolarBrand, setSelectedSolarBrand] = useState<Option>({
    ...emptyOption,
  });
  const [solarBrandList, setSolarBrandList] = useState<Option[]>([
    { value: "", label: "" },
  ]);
  const [selectedSize, setSelectedSize] = useState<Option>({
    ...sizeOption[0],
  });

  const [triggerGenerationServiceProviders] =
    useLazyViewGenerationServiceProvidersQuery();

  useEffect(() => {
    if (!filters.district) {
      setSelectedDistrict({ ...emptyOption });
    }

    if (!filters.generationServiceProviderId) {
      setSelectedSolarBrand({ ...emptyOption });
    }

    if (filters.solarPlantSize === undefined) {
      setSelectedSize(sizeOption[0]);
    }
  }, [filters]);

  useEffect(() => {
    if (show) {
      triggerGenerationServiceProviders()
        .unwrap()
        .then((res: IViewAllGenerationServiceProvidersResponseDTO) => {
          const solarBrandList = res.generationServiceProviders.map(
            (g: IGenerationServiceProviderView) => ({
              value: g.identity,
              label: g.name,
            })
          );
          setSolarBrandList(solarBrandList);
        });
    }
  }, [show, triggerGenerationServiceProviders]);

  useEffect(() => {
    if (
      selectedDistrict?.value &&
      selectedSize.value &&
      selectedSolarBrand?.value
    ) {
      setFilterBadgeValue(3);
    } else if (
      (selectedDistrict?.value && selectedSize.value) ||
      (selectedDistrict?.value && selectedSolarBrand?.value) ||
      (selectedSize.value && selectedSolarBrand?.value)
    ) {
      setFilterBadgeValue(2);
    } else if (
      selectedDistrict?.value ||
      selectedSize.value ||
      selectedSolarBrand?.value
    ) {
      setFilterBadgeValue(1);
    } else {
      setFilterBadgeValue(0);
    }
  }, [
    selectedDistrict?.value,
    selectedSize.value,
    selectedSolarBrand?.value,
    setFilterBadgeValue,
  ]);

  return (
    <FilteringSidePanel
      isOpen={show}
      onClose={onClose}
      onFilter={() => {
        updateFilters(
          selectedDistrict?.value as EDistricts,
          selectedSolarBrand.value,
          selectedSize.value
        );
        onClose();
      }}
      onClearAll={() => {
        setSelectedDistrict({
          value: "",
          label: "",
        });
        setSelectedSolarBrand({
          value: "",
          label: "",
        });
        setSelectedSize({ ...sizeOption[0] });
        onClose();
        updateFilters("", "", "");
      }}
    >
      <>
        <AppSelect
          className="mt-4 font-size-14"
          selectedValue={selectedDistrict}
          label="District"
          options={getDistrict()}
          onChangeOption={(selectedOption) => {
            setSelectedDistrict(selectedOption);
          }}
          isRequired={false}
          labelFontSize="font-size-14"
        />
        <AppSelect
          className="mt-4 font-size-14"
          selectedValue={selectedSolarBrand}
          label="Solar Brand"
          options={solarBrandList}
          onChangeOption={(selectedOption) => {
            setSelectedSolarBrand(selectedOption);
          }}
          isRequired={false}
          labelFontSize="font-size-14"
        />
        <AppSelect
          className="mt-4 font-size-14"
          selectedValue={selectedSize}
          label="Size"
          options={sizeOption}
          onChangeOption={(selectedOption) => {
            setSelectedSize(selectedOption);
          }}
          errors={Error}
          isRequired={false}
          labelFontSize="font-size-14"
        />
      </>
    </FilteringSidePanel>
  );
};

export default SitesFilteringSidePanel;
