import Tooltip from "rc-tooltip";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useLazyGetPaginatedCustomerSolarPlantListQuery } from "../../redux/api/consumer/consumerAPI";
import IListAllSolarPlantsResponseDTO from "../../shared/oversight-core/dtos/response-dtos/list-all-solar-plants-response-dto";
import { EPlantStatus } from "../../shared/oversight-core/enums/plant-status";
import { plantTypeText } from "../../shared/oversight-core/enums/plant-type";
import { AppRoute } from "../../shared/oversight-core/interfaces/app-routes";
import { IShallowSolarPlantView } from "../../shared/oversight-core/interfaces/shallow-solar-plant-view";
import DataTable from "../../shared/oversight-core/ui-elements/data-table/data-table";
import IndicatorTabs from "../../shared/oversight-core/ui-elements/indicator-tabs/indicator-tabs";
import Pagination from "../../shared/oversight-core/ui-elements/pagination/pagination";
import Search from "../../shared/oversight-core/ui-elements/search/search";
import SpinnerModal from "../../shared/oversight-core/ui-elements/spinner/spinner";
import Helper from "../../shared/oversight-core/utils/helpers";
import styles from "./solar-plant-user-list.module.scss";

interface ISearchFilter {
  searchValue: string;
  pageNumber: number;
  pageSize: number;
}

const initialFilters: ISearchFilter = {
  searchValue: "",
  pageNumber: 0,
  pageSize: 10,
};

const SolarPlantUserList = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useState<ISearchFilter>({
    ...initialFilters,
  });
  const [totalElements, setTotalElements] = useState(0);
  const [solarPlantList, setSolarPlantList] = useState<
    IShallowSolarPlantView[]
  >([]);
  const [searchText, setSearchText] = useState("");
  const [selectedTabId, setSelectedTabId] = useState(1);
  const [selectedPlantStatus, setSelectedPlantStatus] = useState(
    EPlantStatus.GENERATING
  );

  const [triggerSolarPlantList, { isFetching }] =
    useLazyGetPaginatedCustomerSolarPlantListQuery();

  useEffect(() => {
    triggerSolarPlantList({
      plantStatus: selectedPlantStatus,
      pageNumber: filters.pageNumber,
      pageSize: filters.pageSize,
      searchValue: filters.searchValue,
    })
      .unwrap()
      .then((res: IListAllSolarPlantsResponseDTO) => {
        setTotalElements(res.genericPage.totalElements);
        setSolarPlantList(res.genericPage.elements);
      })
      .catch(() => {
        setTotalElements(0);
        setSolarPlantList([]);
      });
  }, [triggerSolarPlantList, filters, selectedPlantStatus]);

  const onSearch = () => {
    if (searchText) {
      setFilters({
        searchValue: searchText,
        pageNumber: 0,
        pageSize: 10,
      });
    }
  };

  useEffect(() => {
    if (!searchText) {
      setFilters({ ...initialFilters });
    }
  }, [searchText]);

  const getIndicatorColor = (status: EPlantStatus) => {
    switch (status) {
      case EPlantStatus.FAULT:
        return styles.redIndicator;
      case EPlantStatus.GENERATING:
        return styles.lightGreenIndicator;
      case EPlantStatus.OFFLINE:
        return styles.grayIndicator;
      case EPlantStatus.WAITING:
        return styles.yellowIndicator;
    }
  };

  const handleTabChange = useCallback((tabId: number) => {
    switch (tabId) {
      case 2:
        setSelectedPlantStatus(EPlantStatus.WAITING);
        break;
      case 3:
        setSelectedPlantStatus(EPlantStatus.FAULT);
        break;
      case 4:
        setSelectedPlantStatus(EPlantStatus.OFFLINE);
        break;
      default:
        setSelectedPlantStatus(EPlantStatus.GENERATING);
    }
  }, []);

  useEffect(() => {
    handleTabChange(selectedTabId);
  }, [selectedTabId, handleTabChange]);

  return (
    <div
      className={`${styles.usersListOverflow} container-white position-relative py-4`}
    >
      <Row className="align-items-center">
        <Col className="col-12 col-md-5 col-xl-4">
          <Search
            onSearch={onSearch}
            handleSearchChange={(searchText) => {
              setSearchText(searchText);
            }}
            placeholder="Search Plant Name"
            value={searchText}
          />
        </Col>
        <Col className="mt-3 mt-md-0">
          <IndicatorTabs
            tabs={[
              {
                indicatorColor: "lightGreenIndicator",
                name: "Working",
                selectedColor: "greenLight",
                id: 1,
              },
              {
                indicatorColor: "yellowIndicator",
                name: "Waiting",
                selectedColor: "yellowLight",
                id: 2,
              },
              {
                indicatorColor: "redIndicator",
                name: "Fault",
                selectedColor: "redLight",
                id: 3,
              },
              {
                indicatorColor: "grayIndicator",
                name: "Offline",
                selectedColor: "grayLight",
                id: 4,
              },
            ]}
            onChange={(id) => setSelectedTabId(id)}
            selectedTabId={selectedTabId}
          />
        </Col>
      </Row>
      {solarPlantList.length > 0 ? (
        <>
          <DataTable
            headers={[
              { id: 1, columnName: "" },
              { id: 2, columnName: "Plant" },
              { id: 3, columnName: "Location" },
              { id: 4, columnName: "Power" },
              { id: 5, columnName: "Capacity" },
              { id: 6, columnName: "Specific Yield" },
              { id: 7, columnName: "Today" },
              { id: 8, columnName: "Total" },
              { id: 9, columnName: "Classification" },
              { id: 10, columnName: "" },
            ]}
            data={solarPlantList.map((user) => {
              return {
                data: [
                  <>
                    <div
                      className={`${styles.indicator} ${getIndicatorColor(
                        user.status
                      )}`}
                    ></div>
                  </>,
                  <>
                    {user.plantName.length > 0 ? (
                      <Tooltip
                        placement="top"
                        trigger={["hover"]}
                        overlay={<div>{user.plantName}</div>}
                      >
                        <div
                          className="text-truncate"
                          style={{ maxWidth: "200px" }}
                        >
                          {user.plantName}
                        </div>
                      </Tooltip>
                    ) : (
                      "-"
                    )}
                  </>,
                  <>
                    {user?.address.length > 0 && user.address !== " " ? (
                      <Tooltip
                        placement="top"
                        trigger={["hover"]}
                        overlay={<div>{user.address}</div>}
                      >
                        <div
                          className="text-truncate"
                          style={{ maxWidth: "200px" }}
                        >
                          {user.address}
                        </div>
                      </Tooltip>
                    ) : (
                      "-"
                    )}
                  </>,
                  <>{user.power}kW</>,
                  <>{user.capacity}kW</>,
                  <>{Helper.roundTo2(user.hourlyGeneration)}kWh/kWp</>,
                  <>{Helper.roundTo2(user.todayGeneration)}kWh</>,
                  <>{Helper.roundTo2(user.totalGeneration)}kWh</>,
                  <>{plantTypeText[user.plantType]}</>,
                  <>
                    <button
                      className={styles.button}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(AppRoute.ADMIN_SOLAR + `/${user.plantId}`, {
                          state: {
                            plantId: user.plantId,
                          },
                        });
                      }}
                    >
                      View
                    </button>
                  </>,
                ],
              };
            })}
          />
          <Pagination
            itemsPerPage={filters.pageSize}
            length={totalElements}
            currentPage={filters.pageNumber + 1}
            updateCurrentPage={(pn) => {
              setFilters((ps) => ({ ...ps, pageNumber: pn - 1 }));
            }}
          />
        </>
      ) : (
        <>
          {!isFetching && (
            <div className="container-dash mt-4">
              <Col className="text-center text-light font-size-12">
                There are no solar plants available from the search name.
              </Col>
            </div>
          )}
        </>
      )}
      <SpinnerModal show={isFetching} />
    </div>
  );
};

export default SolarPlantUserList;
