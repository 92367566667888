import { useState } from "react";
import { useSelector } from "react-redux";
import { selectSolarFilter } from "../../../redux/features/solar-filter/solar-filter-slice";
import { EDateTypes } from "../../../shared/oversight-core/enums/date-types";
import { EUnitType } from "../../../shared/oversight-core/enums/unit-type";
import FilteringSidePanel from "../../../shared/oversight-core/shared-components/filtering-side-panel/filtering-side-panel";
import AppDatePicker from "../../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import AppDateRangeSelector from "../../../shared/oversight-core/ui-elements/app-date-range-selector/app-date-range-selector";

interface IProps {
  showFilter: boolean;
  setShowFilter: (value: boolean) => void;
  onFilter: (selectedDate: Date, selectedDateType: EDateTypes) => void;
  onClearAll: () => void;
  selectedUnitType: EUnitType;
}

const SolarFilterSidePanel = (props: IProps) => {
  const solarFilterStore = useSelector(selectSolarFilter);

  const { showFilter, setShowFilter, onFilter, onClearAll, selectedUnitType } =
    props;

  const [selectedDateType, setSelectedDateType] = useState(
    solarFilterStore.selectedDateType
  );
  const [selectedDate, setSelectedDate] = useState(
    new Date(solarFilterStore.selectedDate)
  );

  return (
    <FilteringSidePanel
      isOpen={showFilter}
      onClose={() => {
        setShowFilter(false);
      }}
      onFilter={() => {
        onFilter(selectedDate, selectedDateType);
      }}
      onClearAll={onClearAll}
    >
      <>
        <div className="mt-4">
          {selectedUnitType === EUnitType.POWER ? (
            <AppDatePicker
              label="Time"
              selectedDate={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="dd-MMM-yyyy"
              maxDate={new Date()}
            />
          ) : (
            <AppDateRangeSelector
              startDate={selectedDate}
              endDate={new Date()}
              setSelectedDate={(startDate: Date) => {
                setSelectedDate(startDate);
              }}
              dateTypes={[
                [EDateTypes.DAILY, EDateTypes.MONTHLY, EDateTypes.YEARLY],
              ]}
              selectedDateType={selectedDateType}
              setSelectedDateType={setSelectedDateType}
              maxDate={
                selectedDateType === EDateTypes.DAILY
                  ? new Date()
                  : new Date(
                      new Date().getFullYear(),
                      new Date().getMonth() + 1,
                      0
                    )
              }
              showDatePicker={false}
            />
          )}
        </div>
      </>
    </FilteringSidePanel>
  );
};

export default SolarFilterSidePanel;
