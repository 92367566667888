import { Col, Row } from "react-bootstrap";
import MaterialIcon, { MtIcon } from "../material-icon/material-icon";
import styles from "./tabs-icon-button.module.scss";

export interface IconTabComponentInterface {
  iconName: MtIcon;
  id: number;
}

interface IProps {
  buttons: IconTabComponentInterface[];
  onChange: (id: number) => void;
  selectedTabId: number;
}

const TabsIconButton = (props: IProps) => {
  const { buttons, onChange, selectedTabId } = props;

  return (
    <Row className={`${styles.tabIconButton} justify-content-center mx-0`}>
      {buttons.map((button, index) => (
        <Col xs="auto" key={index} className={`${styles.divider}`}>
          <MaterialIcon
            icon={button.iconName}
            color={selectedTabId === button.id ? `#2F2A89` : `#69768B`}
            onClick={() => onChange(button.id)}
            className="cursor-pointer"
          />
        </Col>
      ))}
    </Row>
  );
};

export default TabsIconButton;
