import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { IMapLocation } from "../../../shared/oversight-core/interfaces/map-location";
import GoogleMapModal from "../../../shared/oversight-core/shared-components/google-map-modal/google-map-modal";
import AppSelect, {
  Option,
} from "../../../shared/oversight-core/ui-elements/app-select/app-select";
import AppInput from "../../../shared/oversight-core/ui-elements/input/app-input";
import MaterialIcon from "../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import ModalContainer, {
  ModelContainerProps,
} from "../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import getDistrict from "../../../shared/oversight-core/utils/get-district";
import { noSpecialCharsNoWhitespaceStartEndAndNotAllowCommaRegex } from "../../../shared/oversight-core/utils/regex";

interface IFormInput {
  siteName: string;
  client: string;
  location: string;
  url: string | null;
  longitude: number;
  latitude: number;
  district: Option | [];
  inverterBrand: Option | [];
}

const defaultFormValues: IFormInput = {
  siteName: "",
  client: "",
  location: "",
  url: "",
  longitude: 0,
  latitude: 0,
  district: [],
  inverterBrand: [],
};

const defaultLocationDetails: IMapLocation = {
  name: "",
  lng: 79.8612,
  lat: 6.9271,
};

interface IProps extends ModelContainerProps {
  show: boolean;
  isEdit: boolean;
  setUpdateCurrentSite: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddUpdateSiteModal = (props: IProps) => {
  const { show, isEdit, setUpdateCurrentSite, ...rest } = props;

  const [isMapOpen, setIsMapOpen] = useState(false);
  const [selectedLocationDetails, setSelectedLocationDetails] =
    useState<IMapLocation>({ ...defaultLocationDetails });

  const {
    register,
    formState: { errors },
    reset,
    control,
  } = useForm<IFormInput>({
    defaultValues: { ...defaultFormValues },
  });

  return (
    <>
      <ModalContainer
        {...rest}
        show={show}
        size="modal-lg"
        title={`${isEdit ? "Edit" : "Add"} Site`}
        confirmButtonText="Confirm"
        cancelButtonText="Cancel"
        isLoading={false}
        onCancel={() => {
          reset({ ...defaultFormValues });
          setSelectedLocationDetails({ ...defaultLocationDetails });
          if (props.onCancel) {
            props.onCancel();
          }
        }}
        onClose={() => {
          reset({ ...defaultFormValues });
          setSelectedLocationDetails({ ...defaultLocationDetails });
          if (props.onClose) {
            props.onClose();
          }
        }}
      >
        <>
          <AppInput
            label="Site Name"
            name="siteName"
            placeholder="Site Name"
            register={register("siteName", {
              required: "Site Name is required",
              maxLength: {
                value: 20,
                message:
                  "You have exceeded the maximum number of 20 characters in this field",
              },
              pattern: {
                value: noSpecialCharsNoWhitespaceStartEndAndNotAllowCommaRegex,
                message:
                  "Entered value can't start/end or contain only white spaces and can't contain any special characters.",
              },
            })}
            errors={errors}
          />
          <AppInput
            className="mt-4"
            label="Client"
            name="client"
            placeholder="Client"
            register={register("client", {
              required: "Client Name is required",
              maxLength: {
                value: 20,
                message:
                  "You have exceeded the maximum number of 20 characters in this field",
              },
              pattern: {
                value: noSpecialCharsNoWhitespaceStartEndAndNotAllowCommaRegex,
                message:
                  "Entered value can't start/end or contain only white spaces and can't contain any special characters.",
              },
            })}
            errors={errors}
          />
          <AppInput
            className="mt-4"
            label="Location"
            name="location"
            placeholder="Location"
            register={register("location", {
              required: "Location is required",
            })}
            errors={errors}
            icon="location_on"
            onInputClick={() => setIsMapOpen(true)}
          />
          <AppSelect
            className="mt-4"
            defaultValue={""}
            name="district"
            label="District"
            placeholder="District"
            options={getDistrict()}
            errors={errors}
            isRequired={true}
            control={control}
            register={register("district", {
              required: "Please select the district",
            })}
            menuHeight="180px"
          />
          <AppSelect
            className="mt-4"
            defaultValue={""}
            label="Inverter Brand"
            name="inverterBrand"
            placeholder="Inverter Brand"
            options={[]}
            errors={errors}
            isRequired={true}
            control={control}
            register={register("inverterBrand", {
              required: "Please select the inverter brand",
            })}
            menuHeight="100px"
          />
          <Row className="justify-content-center mt-4">
            <Col className="col-auto cursor-pointer">
              <Row className="align-items-center">
                <Col className="col-auto pe-0">
                  <MaterialIcon icon="link" color="#2F2A89" />
                </Col>
                <Col className="text-light font-size-14 font-weight-500">
                  Link Solar Plant
                </Col>
              </Row>
            </Col>
          </Row>
          <GoogleMapModal
            show={isMapOpen}
            onClose={() => setIsMapOpen(false)}
            setSelectedLocationDetails={setSelectedLocationDetails}
            selectedLocationDetails={selectedLocationDetails}
          />
        </>
      </ModalContainer>
    </>
  );
};

export default AddUpdateSiteModal;
