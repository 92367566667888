import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

type TabState = {
  siteViewTabId: number;
};

const initialState: TabState = {
  siteViewTabId: 1,
};

export const siteSlice = createSlice({
  name: "station",
  initialState: { ...initialState },
  reducers: {
    setSiteViewTabId: (state, action: PayloadAction<number>) => {
      state.siteViewTabId = action.payload;
    },
  },
});

export const { setSiteViewTabId } = siteSlice.actions;

export const siteViewTabId = (state: RootState) => state.sites.siteViewTabId;

export default siteSlice.reducer;
