import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLazyGetPaginatedCustomerSolarPlantListAdminQuery } from "../../redux/api/dashboard/dashboardAPI";
import {
  setSiteViewTabId,
  siteViewTabId,
} from "../../redux/features/sites/sites-slice";
import SiteCard from "../../shared/components/site-card/site-card";
import IListAllSolarPlantsResponseDTO from "../../shared/oversight-core/dtos/response-dtos/list-all-solar-plants-response-dto";
import { EDistricts } from "../../shared/oversight-core/enums/districts";
import { ESolarPlantSize } from "../../shared/oversight-core/enums/solar-plant-size";
import { IMapLocation } from "../../shared/oversight-core/interfaces/map-location";
import { ISearchFilters } from "../../shared/oversight-core/interfaces/search-filters";
import { IShallowSolarPlantView } from "../../shared/oversight-core/interfaces/shallow-solar-plant-view";
import GoogleMapDetails from "../../shared/oversight-core/shared-components/google-map-details/google-map-details";
import ButtonWithBadge from "../../shared/oversight-core/ui-elements/buttons/button-with-badge/button-with-badge";
import Pagination from "../../shared/oversight-core/ui-elements/pagination/pagination";
import Search from "../../shared/oversight-core/ui-elements/search/search";
import SpinnerModal from "../../shared/oversight-core/ui-elements/spinner/spinner";
import TabsIconButton from "../../shared/oversight-core/ui-elements/tabs-icon-button/tabs-icon-button";
import AddUpdateSiteModal from "./add-update-site-modal/add-update-site-modal";
import SitesFilteringSidePanel from "./sites-filtering-side-panel/sites-filtering-side-panel";
import styles from "./sites.module.scss";

export interface IExtendedSearchFilters extends ISearchFilters {
  searchValue?: string;
  district?: string;
  generationServiceProviderId?: string;
  solarPlantSize?: string;
}

const initialFilters: IExtendedSearchFilters = {
  district: "",
  generationServiceProviderId: "",
  solarPlantSize: "",
  searchValue: "",
  pageNumber: 0,
};

const Sites = () => {
  const [filters, setFilters] = useState<IExtendedSearchFilters>({
    ...initialFilters,
  });
  const [searchText, setSearchText] = useState("");
  const [showFilteringSidePanel, setShowFilteringSidePanel] = useState(false);
  const [locations, setLocations] = useState<IMapLocation[]>([]);
  const [showAddUpdateSiteModal, setSHowAddUpdateSiteModal] = useState(false);
  const [solarPlantList, setSolarPlantList] = useState<
    IShallowSolarPlantView[]
  >([]);
  const [totalElements, setTotalElements] = useState(0);
  const [selectedSolarPlant, setSelectedSolarPlant] =
    useState<IShallowSolarPlantView>();
  const [filterBadgeValue, setFilterBadgeValue] = useState(0);

  const selectedTabId = useSelector(siteViewTabId);

  const { pathname, state } = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [triggerCustomerSolarPlantList, { isFetching }] =
    useLazyGetPaginatedCustomerSolarPlantListAdminQuery();

  const handleTabChange = (id: number) => {
    setFilters({ ...initialFilters });
    dispatch(setSiteViewTabId(id));
  };

  useEffect(() => {
    if (state) {
      dispatch(setSiteViewTabId(state.tabId));
    }
  }, [dispatch, state]);

  useEffect(() => {
    const filterParams: IExtendedSearchFilters = {
      pageNumber: filters.pageNumber,
      pageSize: selectedTabId === 1 ? 15 : 5,
      searchValue: filters.searchValue,
      ascending: true,
    };

    if (filters.district) {
      filterParams.district = filters.district;
    }

    if (filters.generationServiceProviderId) {
      filterParams.generationServiceProviderId =
        filters.generationServiceProviderId;
    }

    if (filters.solarPlantSize) {
      filterParams.solarPlantSize = filters.solarPlantSize;
    }

    triggerCustomerSolarPlantList(filterParams)
      .unwrap()
      .then((res: IListAllSolarPlantsResponseDTO) => {
        setSolarPlantList(res.genericPage.elements);
        setTotalElements(res.genericPage.totalElements);
        setLocations(
          res.genericPage.elements.map((e) => {
            return {
              lat: e.latitude,
              lng: e.longitude,
              name: e.address,
            };
          })
        );
      })
      .catch(() => {
        setTotalElements(0);
      });
  }, [triggerCustomerSolarPlantList, filters, selectedTabId]);

  const onSearch = () => {
    if (searchText) {
      setFilters({
        searchValue: searchText,
        pageNumber: 0,
      });
    }
  };

  useEffect(() => {
    if (!searchText) {
      setFilters({ ...initialFilters });
    }
  }, [searchText]);

  const updateFilters = (
    district: EDistricts | string,
    generationServiceProviderId: string,
    solarPlantSize: ESolarPlantSize | string
  ) => {
    setFilters({
      district: district,
      generationServiceProviderId: generationServiceProviderId,
      solarPlantSize: solarPlantSize,
      pageNumber: 0,
    });
  };

  return (
    <>
      <Row>
        <Col className="col-12 col-sm">
          <Row className="align-items-center gy-3">
            <Col className="col-12 col-sm-5 col-xl-3 pe-4 pe-sm-2">
              <Search
                onSearch={onSearch}
                handleSearchChange={(searchText) => {
                  setSearchText(searchText);
                }}
                placeholder="Search Sites"
                value={searchText}
              />
            </Col>
            {/* <Col className="col-12 col-sm-auto pe-sm-0">
              <AppButton
                text="Add Site"
                onClick={() => setSHowAddUpdateSiteModal(true)}
                className="px-4"
              />
            </Col> */}
            <Col className="col-6 col-sm-auto">
              <TabsIconButton
                buttons={[
                  { iconName: "format_list_bulleted", id: 1 },
                  { iconName: "distance", id: 2 },
                ]}
                onChange={handleTabChange}
                selectedTabId={selectedTabId}
              />
            </Col>
            <Col className="col-6 d-block d-sm-none">
              <ButtonWithBadge
                badgeValue={filterBadgeValue}
                text="Filter"
                icon="filter_alt"
                onClick={() => setShowFilteringSidePanel(true)}
              />
            </Col>
          </Row>
        </Col>
        <Col className="col-auto d-none d-sm-block">
          <ButtonWithBadge
            badgeValue={filterBadgeValue}
            text="Filter"
            icon="filter_alt"
            onClick={() => setShowFilteringSidePanel(true)}
          />
        </Col>
      </Row>
      <div className="mt-4 position-relative">
        {solarPlantList.length > 0 ? (
          selectedTabId === 1 ? (
            <div className="container-white">
              <Row className="my-3 gy-2 gx-3">
                {solarPlantList.map((s, index) => (
                  <Col key={index} className="col-12 col-lg-6 col-xxl-4">
                    <SiteCard
                      solarPlant={s}
                      onClick={() =>
                        navigate(pathname + "/" + s.plantId, {
                          state: { solar: s },
                        })
                      }
                      selectedTabId={selectedTabId}
                    />
                  </Col>
                ))}
              </Row>
              <Row className="align-items-center mt-5 flex-column flex-sm-row mb-3">
                <Col>
                  <Pagination
                    itemsPerPage={15}
                    length={totalElements}
                    currentPage={filters.pageNumber + 1}
                    updateCurrentPage={(pn) => {
                      setFilters((ps) => ({ ...ps, pageNumber: pn - 1 }));
                    }}
                  />
                </Col>
                <Col className="col-auto text-light font-size-12 mt-3 mt-sm-0">
                  {solarPlantList.length}/{totalElements} Sites
                </Col>
              </Row>
            </div>
          ) : (
            <div className={`${styles.sitesMapView} p-3`}>
              <Row className="align-items-start">
                <Col className="col-12 col-xl">
                  <GoogleMapDetails locations={locations} />
                </Col>
                <Col className="mt-3 mt-xl-0">
                  {filters.district && (
                    <Row>
                      <Col className="text-dark font-weight-600 font-size-16 mt-2">
                        Site Near : {filters.district}
                      </Col>
                    </Row>
                  )}
                  <Row className="mt-4">
                    {solarPlantList.map((s, index) => (
                      <Col key={index} className="col-12 mt-2">
                        <SiteCard
                          solarPlant={s}
                          onClick={() =>
                            navigate(pathname + "/" + s.plantId, {
                              state: { solar: s },
                            })
                          }
                          onSelect={() => setSelectedSolarPlant(s)}
                          selectedSolarPlant={selectedSolarPlant}
                          selectedTabId={selectedTabId}
                        />
                      </Col>
                    ))}
                  </Row>
                  <Row className="mt-5">
                    <Col>
                      <Pagination
                        itemsPerPage={5}
                        length={totalElements}
                        currentPage={filters.pageNumber + 1}
                        updateCurrentPage={(pn) => {
                          setFilters((ps) => ({ ...ps, pageNumber: pn - 1 }));
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-light font-size-12 mt-3 text-center">
                      {solarPlantList.length}/{totalElements} Sites
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          )
        ) : (
          <>
            {
              //Should be add !isFetching
              <div className="container-dash text-center text-light font-weight-400 font-size-14">
                {searchText !== "" ||
                filters.district ||
                filters.generationServiceProviderId ||
                filters.solarPlantSize ? (
                  `There are no data available for the selected filters.`
                ) : (
                  <>
                    There are no sites added to the system.&nbsp;
                    <span
                      className="text-primary text-decoration-underline cursor-pointer"
                      onClick={() => {
                        setSHowAddUpdateSiteModal(true);
                        // setSelectedStation(undefined);
                      }}
                    >
                      Add Site
                    </span>
                  </>
                )}
              </div>
            }
          </>
        )}
        <SpinnerModal show={isFetching} />
      </div>
      <AddUpdateSiteModal
        show={showAddUpdateSiteModal}
        onClose={() => setSHowAddUpdateSiteModal(false)}
        onCancel={() => setSHowAddUpdateSiteModal(false)}
        isEdit={false}
        setUpdateCurrentSite={() => {}}
      />
      <SitesFilteringSidePanel
        show={showFilteringSidePanel}
        onClose={() => setShowFilteringSidePanel(false)}
        updateFilters={updateFilters}
        setFilterBadgeValue={setFilterBadgeValue}
        filters={filters}
      />
    </>
  );
};

export default Sites;
