import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  useLazyGetActualConsumptionsQuery,
  useLazyGetCountBillingSpacesQuery,
  useLazyGetCountConsumersQuery,
  useLazyGetDeviceTypesByDevicePowerConsumptionQuery,
  useLazyGetTotalAveragePowerUsageQuery,
} from "../../redux/api/dashboard/dashboardAPI";
import CountBillingSpacesResponseDTO from "../../shared/oversight-core/dtos/response-dtos/count-billing-spaces-response-dto";
import CountConsumersResponseDTO from "../../shared/oversight-core/dtos/response-dtos/count-consumers-response-dto";
import { IDeviceTypePowerConsumption } from "../../shared/oversight-core/dtos/response-dtos/get-device-types-by-device-power-consumption-response-dto";
import GetDeviceTypesByTotalAveragePowerUsageResponseDTO from "../../shared/oversight-core/dtos/response-dtos/get-device-types-by-total-average-power-usage-response-dto";
import ViewServiceProviderPreviousConsumedEnergyResponseDTO from "../../shared/oversight-core/dtos/response-dtos/view-service-provider-previous-consumed-energy-response-dto";
import { UserRole } from "../../shared/oversight-core/enums/user-roles";
import { IDeviceStatisticsByTypeView } from "../../shared/oversight-core/interfaces/entities/device-statistics-by-type-view";
import IMonthlyServiceProviderEnergyView from "../../shared/oversight-core/interfaces/monthly-service-provider-energy-view";
import BarChart from "../../shared/oversight-core/ui-elements/bar-chart/bar-chart";
import NoChartDataView from "../../shared/oversight-core/ui-elements/no-chart-data-view/no-chart-data-view";
import SpinnerModal from "../../shared/oversight-core/ui-elements/spinner/spinner";
import { capitalizeFirstLetter } from "../../shared/oversight-core/utils/capitalize-first-letter";
import { selectProfile } from "./../../redux/features/user/user-slice";
import AdminSolarDashboard from "./admin-solar-dashboard/admin-solar-dashboard";
import HighEnergyConsumingDevices from "./high-energy-consuming-devices/high-energy-consuming-devices";
import HighPowerDevices from "./high-power-devices/high-power-devices";
import HighestEnergyConsumption from "./highest-energy-consumption/highest-energy-consumption";
import TotalUsers from "./total-users/total-users";

const AdminDashboard = () => {
  const profile = useSelector(selectProfile);

  const [countConsumers, setCountConsumers] =
    useState<CountConsumersResponseDTO>();
  const [countBillingSpaces, setCountBillingSpaces] =
    useState<CountBillingSpacesResponseDTO>();
  const [totalAveragePowerUsage, setTotalAveragePowerUsage] =
    useState<IDeviceStatisticsByTypeView[]>();
  const [actualConsumption, setActualConsumption] = useState<
    IMonthlyServiceProviderEnergyView[]
  >([]);
  const [
    deviceTypesByDevicePowerConsumption,
    setDeviceTypesByDevicePowerConsumption,
  ] = useState<IDeviceTypePowerConsumption[]>([]);
  const [totalConsumption, setTotalConsumption] = useState<number[]>([]);
  const [chartDataAvailable, setChartDataAvailable] = useState(false);

  const [triggerGetCountConsumers, { isFetching: isFetchingCountConsumers }] =
    useLazyGetCountConsumersQuery();

  const [
    triggerGetCountBillingSpaces,
    { isFetching: isFetchingCountBillingSpace },
  ] = useLazyGetCountBillingSpacesQuery();

  const [
    triggerGetTotalAveragePowerUsage,
    { isFetching: isFetchingTotalPowerUsage },
  ] = useLazyGetTotalAveragePowerUsageQuery();

  const [
    triggerGetActualConsumption,
    { isFetching: isFetchingActualConsumption },
  ] = useLazyGetActualConsumptionsQuery();

  const [
    triggerGetDeviceTypesByDevicePowerConsumption,
    { isFetching: isFetchingGetDeviceTypesByDevicePowerConsumption },
  ] = useLazyGetDeviceTypesByDevicePowerConsumptionQuery();

  useEffect(() => {
    if (profile && profile.role === UserRole.ROLE_ADMIN) {
      triggerGetCountConsumers()
        .unwrap()
        .then((response: CountConsumersResponseDTO) => {
          setCountConsumers(response);
        })
        .catch((error) => {
          console.log(error);
        });
      triggerGetCountBillingSpaces()
        .unwrap()
        .then((response: CountBillingSpacesResponseDTO) => {
          setCountBillingSpaces(response);
        })
        .catch((error) => {
          console.log(error);
        });
      triggerGetTotalAveragePowerUsage()
        .unwrap()
        .then((response: GetDeviceTypesByTotalAveragePowerUsageResponseDTO) => {
          setTotalAveragePowerUsage(response.deviceTypeGroups);
        })
        .catch(() => {
          setTotalAveragePowerUsage([]);
        });
      triggerGetActualConsumption({ monthLimit: 6 })
        .unwrap()
        .then(
          (response: ViewServiceProviderPreviousConsumedEnergyResponseDTO) => {
            setActualConsumption(response.monthlyServiceProviderEnergy);
          }
        )
        .catch(() => {
          setActualConsumption([]);
        });
      triggerGetDeviceTypesByDevicePowerConsumption({})
        .unwrap()
        .then((response) => {
          setDeviceTypesByDevicePowerConsumption(response.deviceTypeGroups);
        })
        .catch(() => {
          setDeviceTypesByDevicePowerConsumption([]);
        });
    }
  }, [
    triggerGetCountConsumers,
    triggerGetCountBillingSpaces,
    triggerGetTotalAveragePowerUsage,
    triggerGetActualConsumption,
    triggerGetDeviceTypesByDevicePowerConsumption,
    profile,
  ]);

  const monthsList = actualConsumption.map((m) =>
    capitalizeFirstLetter(m.monthName)
  );

  useEffect(() => {
    const tempTotalConsumption: number[] = [];
    if (actualConsumption) {
      actualConsumption.forEach((value) => {
        if (value && value.energyViews && value.energyViews.length !== 0) {
          let totalEnergyInUnits = 0;
          value.energyViews.forEach((v) => {
            if (v.energyAdminView) {
              totalEnergyInUnits += v.energyAdminView.energyInUnits || 0;
            }
          });
          tempTotalConsumption.push(parseFloat(totalEnergyInUnits.toFixed(2)));
        } else {
          tempTotalConsumption.push(0);
        }
      });
    }

    setTotalConsumption(tempTotalConsumption);

    const isDataAvailable = tempTotalConsumption.some((data) => data > 0);

    setChartDataAvailable(isDataAvailable);
  }, [actualConsumption]);

  return (
    <>
      {profile && profile.role === UserRole.ROLE_SOLAR_DASHBOARD_ADMIN ? (
        <AdminSolarDashboard />
      ) : (
        <>
          <Row>
            <Col className="col-12 col-lg-6">
              <HighestEnergyConsumption />
            </Col>
            <Col className="col-12 col-lg-6">
              <Row className="mt-4 mt-lg-0">
                <Col className="col-12 col-md-6 mb-3 mb-md-0">
                  <TotalUsers
                    title={"Total Users"}
                    counts={{
                      totalUsers: countConsumers?.totalRegisteredCustomers || 0,
                    }}
                    isFetching={isFetchingCountConsumers}
                  />
                </Col>
                <Col className="ps-lg-0">
                  <TotalUsers
                    title={"Total Billing Spaces"}
                    counts={{
                      totalUsers: countBillingSpaces?.totalBillingSpaces || 0,
                    }}
                    isFetching={isFetchingCountBillingSpace}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <HighPowerDevices
                    consumers={
                      totalAveragePowerUsage?.map((item) => {
                        const powerConsumer: IDeviceStatisticsByTypeView = {
                          type: item.type,
                          minAveragePowerUsage: item.minAveragePowerUsage,
                          maxAveragePowerUsage: item.maxAveragePowerUsage,
                          deviceCount: item.deviceCount,
                          countAsPercentage: item.countAsPercentage,
                        };
                        return powerConsumer;
                      }) || []
                    }
                    title="Device Types with Highest Device Count"
                    isFetching={isFetchingTotalPowerUsage}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <HighEnergyConsumingDevices
                highestPowerConsumingDevices={
                  deviceTypesByDevicePowerConsumption
                }
                title="Device Types with Highest Energy Consumption Last Month"
                isFetching={isFetchingGetDeviceTypesByDevicePowerConsumption}
              />
            </Col>
          </Row>
          <div className="container-white mt-4">
            <Row className="mt-2">
              <Col>
                {totalConsumption.length === 0 &&
                !isFetchingActualConsumption ? (
                  <NoChartDataView />
                ) : (
                  <div className="position-relative">
                    <BarChart
                      borderColor1="#5FA7C6"
                      backgroundColor1="#2991CC17"
                      dataLabel1="Total Energy Consumptions of Users"
                      yAxesUnit="Units"
                      labels={monthsList}
                      data1={totalConsumption || []}
                      isChartDataAvailable={chartDataAvailable}
                    />
                    <SpinnerModal show={isFetchingActualConsumption} />
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  );
};

export default AdminDashboard;
