import { EPowerMeasureType } from "../enums/power-measure-type";

export const powerToLabel = (convertPowerMeasureType: EPowerMeasureType) => {
  switch (convertPowerMeasureType) {
    case EPowerMeasureType.KILO_WATT:
      return "kW";
    case EPowerMeasureType.MEGA_WATT:
      return "MW";
    case EPowerMeasureType.GIGA_WATT:
      return "GW";
    default:
      return "W";
  }
};
