import styles from "./site-stats.module.scss";

interface IProps {
  value: number;
}

const SiteStats = (props: IProps) => {
  const { value } = props;

  return (
    <div className={styles.background}>
      <div className="font-dark font-weight-500 font-size-12">{value} kW</div>
    </div>
  );
};
export default SiteStats;
