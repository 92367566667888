import moment from "moment";
import { Col, Row } from "react-bootstrap";
import {
  batteryStatusTypeText,
  EBatteryStatusType,
} from "../../enums/battery-status-type";
import { IBatteryInverterViews } from "../../interfaces/battery-inverter-views";
import { ISolarPlantInverterView } from "../../interfaces/solar-plant-inverter-view";
import Gauge from "../../ui-elements/gauge/gauge";
import Helper from "../../utils/helpers";
import styles from "./inverter-info-card.module.scss";
import InverterInformation from "./inverter-information/inverter-information";

interface IProps {
  solarPlantInverter?: ISolarPlantInverterView;
  batteryInverter?: IBatteryInverterViews;
}

const InverterInfoCard = (props: IProps) => {
  const { solarPlantInverter, batteryInverter } = props;

  return (
    <>
      <div className={`${styles.infoCardContainer} py-4 px-5`}>
        <Row className="align-items-center">
          <Col className="text-dark font-weight-500 font-size-24">
            {solarPlantInverter?.modelType ?? batteryInverter?.modelType}
          </Col>
        </Row>
        <Row className="align-items-center justify-content-between my-1 g-4">
          <Col className="col-12 col-lg-auto">
            <Row className="justify-content-center justify-content-lg-start">
              <Col className="col-auto">
                <Gauge
                  icon="solar_power"
                  value={
                    solarPlantInverter?.dailyGeneration ??
                    Number(batteryInverter?.dailyGeneration)
                  }
                  maxValue={
                    solarPlantInverter?.capacity ??
                    Number(batteryInverter?.capacity)
                  }
                  circleRatio={1}
                  unit={"kWh"}
                  trailColor="#D08700"
                  width={150}
                  height={75}
                  isDifferentStyles
                />
              </Col>
            </Row>
            <Row className="justify-content-center mt-5 pt-5">
              <Col className="col-auto text-light font-weight-500 font-size-16">
                Today Generation
              </Col>
            </Row>
          </Col>
          <Col className="col-12 col-sm-6 col-lg-auto">
            <InverterInformation
              inverterInfo={[
                {
                  title: "Model",
                  value:
                    solarPlantInverter?.modelType ?? batteryInverter?.modelType,
                },
                {
                  title: "SN",
                  value:
                    solarPlantInverter?.inverterSN ??
                    batteryInverter?.inverterSn,
                },
                {
                  title: "Check Code",
                  value:
                    solarPlantInverter?.checkCode ?? batteryInverter?.checkCode,
                },
                {
                  title: "Capacity",
                  value: `${
                    solarPlantInverter?.capacity ?? batteryInverter?.capacity
                  } kW`,
                },
                {
                  title: `${
                    solarPlantInverter?.connected
                      ? "Connected"
                      : "Battery Health"
                  }`,
                  value: solarPlantInverter?.connected
                    ? moment(solarPlantInverter?.connected)
                        .utcOffset("+05:30")
                        .format("YYYY-MM-DD HH:mm")
                    : batteryInverter?.soh,
                },
              ]}
            />
          </Col>
          <Col className="col-12 col-sm-6 col-lg-auto">
            <InverterInformation
              inverterInfo={[
                {
                  title: "Inner Temp",
                  value: (
                    <>
                      {solarPlantInverter?.innerTemperature ??
                        batteryInverter?.innerTemperature}{" "}
                      C&deg;
                    </>
                  ),
                },
                {
                  title: `${
                    solarPlantInverter?.gridPower ? `Power` : `Output Power`
                  }`,
                  value: `${
                    solarPlantInverter?.gridPower ??
                    batteryInverter?.outputPower
                  } kW`,
                },
                {
                  title: `${
                    solarPlantInverter?.acCurrent1
                      ? `AC Current`
                      : `Output Voltage`
                  }`,
                  value: `${
                    solarPlantInverter?.acCurrent1 ??
                    batteryInverter?.outputVoltage
                  } A`,
                },
                {
                  title: `${
                    solarPlantInverter?.acVoltage1
                      ? `AC Voltage`
                      : `Battery Status`
                  }`,
                  value: solarPlantInverter?.acVoltage1
                    ? `${solarPlantInverter?.acVoltage1} V`
                    : batteryStatusTypeText[
                        batteryInverter?.batteryStatus as EBatteryStatusType
                      ],
                },
                {
                  title: `${
                    solarPlantInverter?.acFrequency1
                      ? `AC Frequency`
                      : `Battery`
                  }`,
                  value: solarPlantInverter?.acFrequency1
                    ? `${solarPlantInverter?.acFrequency1} Hz`
                    : `${batteryInverter?.batteryV}/${batteryInverter?.batteryA}/${batteryInverter?.batteryW}(V/A/W)`,
                },
              ]}
            />
          </Col>
          <Col className="col-12 col-xxl-auto">
            <Row className="g-4">
              <Col className={` col-12 col-sm-6 col-xxl-12`}>
                <div className={styles.background1}>
                  <div className="text-light font-weight-500 font-size-16">
                    Total Generation
                  </div>
                  <div className="text-dark font-weight-500 font-size-16 mt-2">
                    {Helper.roundTo2(
                      solarPlantInverter?.totalGeneration ??
                        Number(batteryInverter?.totalGeneration)
                    )}{" "}
                    kWh
                  </div>
                </div>
              </Col>
              <Col className={` col-12 col-sm-6 col-xxl-12`}>
                <div className={styles.background1}>
                  <div className="text-light font-weight-500 font-size-16">
                    {solarPlantInverter?.hourTotal
                      ? `Total Hours`
                      : `Battery Percentage`}
                  </div>
                  <div className="text-dark font-weight-500 font-size-16 mt-2">
                    {solarPlantInverter?.hourTotal
                      ? `${solarPlantInverter?.hourTotal} H`
                      : `${batteryInverter?.soc}%`}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default InverterInfoCard;
