import CountBillingSpacesResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/count-billing-spaces-response-dto";
import CountConsumersResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/count-consumers-response-dto";
import GetDeviceTypesByDevicePowerConsumptionResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/get-device-types-by-device-power-consumption-response-dto";
import GetDeviceTypesByTotalAveragePowerUsageResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/get-device-types-by-total-average-power-usage-response-dto";
import IListAllSolarPlantsResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/list-all-solar-plants-response-dto";
import IViewCustomerSolarPlantDetailsResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-customer-solar-plant-details-response-dto";
import ViewHighestConsumedSpaceClustersOfLastMonthResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-highest-consumed-space-clusters-off-last-month-response-dto";
import ViewServiceProviderPreviousConsumedEnergyResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-service-provider-previous-consumed-energy-response-dto";
import { IViewTotalSolarPlantEnergyResponseDTO } from "../../../shared/oversight-core/dtos/response-dtos/view-total-solar-plant-energy-response-dto";
import { EPlantStatus } from "../../../shared/oversight-core/enums/plant-status";
import { transformErrorResponse } from "../../../shared/oversight-core/utils/transformErrorResponse";
import { rootApi } from "../apiManager";

export const adminDashboardAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getHighestConsumptions: builder.query<
      ViewHighestConsumedSpaceClustersOfLastMonthResponseDTO,
      { limit: number }
    >({
      transformErrorResponse,
      query(limit) {
        return {
          url: `/admin/space-cluster/consumption/actual/last-month/view`,
          method: "GET",
          params: limit,
        };
      },
    }),
    getCountConsumers: builder.query<CountConsumersResponseDTO, void>({
      transformErrorResponse,
      query() {
        return {
          url: `/admin/customer/count`,
          method: "GET",
        };
      },
    }),
    getCountBillingSpaces: builder.query<CountBillingSpacesResponseDTO, void>({
      transformErrorResponse,
      query() {
        return {
          url: `/admin/space-cluster/count`,
          method: "GET",
        };
      },
    }),
    getTotalAveragePowerUsage: builder.query<
      GetDeviceTypesByTotalAveragePowerUsageResponseDTO,
      void
    >({
      transformErrorResponse,
      query() {
        return {
          url: `/admin/power-consumer/by-device-count/sort`,
          method: "GET",
        };
      },
    }),
    getActualConsumptions: builder.query<
      ViewServiceProviderPreviousConsumedEnergyResponseDTO,
      { monthLimit: number }
    >({
      transformErrorResponse,
      query(monthLimit) {
        return {
          url: `/admin/service-provider/consumed-energy/previous/view`,
          method: "GET",
          params: monthLimit,
        };
      },
    }),
    getDeviceTypesByDevicePowerConsumption: builder.query<
      GetDeviceTypesByDevicePowerConsumptionResponseDTO,
      { limit?: number }
    >({
      transformErrorResponse,
      query(limit) {
        return {
          url: `/admin/power-consumer/highest-power-consumption/sort`,
          method: "GET",
          params: limit,
        };
      },
    }),
    viewSolarPlantStatisticsAdmin: builder.query<
      IViewCustomerSolarPlantDetailsResponseDTO,
      void
    >({
      transformErrorResponse,
      query() {
        return {
          url: `/admin/generation-service-provider/statistics`,
          method: "GET",
        };
      },
    }),
    getPaginatedCustomerSolarPlantListAdmin: builder.query<
      IListAllSolarPlantsResponseDTO,
      {
        plantStatus?: EPlantStatus;
        searchValue?: string;
        type?: string;
        district?: string;
        generationServiceProviderId?: string;
        solarPlantSize?: string;
        pageNumber?: number;
        pageSize?: number;
        sortBy?: string;
        ascending?: boolean;
      }
    >({
      transformErrorResponse,
      query({
        plantStatus,
        searchValue,
        type,
        district,
        generationServiceProviderId,
        solarPlantSize,
        pageNumber,
        pageSize,
        sortBy,
        ascending,
      }) {
        return {
          url: `/admin/customer/solar-plant/list`,
          method: "GET",
          params: {
            plantStatus,
            searchValue,
            type,
            district,
            generationServiceProviderId,
            solarPlantSize,
            pageNumber,
            pageSize,
            sortBy,
            ascending,
          },
        };
      },
    }),
    viewTotalPlantGeneration: builder.query<
      IViewTotalSolarPlantEnergyResponseDTO,
      {
        year: number;
        month: number;
      }
    >({
      transformErrorResponse,
      query({ year, month }) {
        return {
          url: `admin/generation-service-provider/energy-generation/view`,
          method: "GET",
          params: { year, month },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetHighestConsumptionsQuery,
  useLazyGetCountConsumersQuery,
  useLazyGetCountBillingSpacesQuery,
  useLazyGetTotalAveragePowerUsageQuery,
  useLazyGetActualConsumptionsQuery,
  useLazyGetDeviceTypesByDevicePowerConsumptionQuery,
  useLazyViewSolarPlantStatisticsAdminQuery,
  useLazyGetPaginatedCustomerSolarPlantListAdminQuery,
  useLazyViewTotalPlantGenerationQuery,
} = adminDashboardAPI;
