import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { IMapLocation } from "../../interfaces/map-location";
import SiteStats from "../../ui-elements/site-stats/site-stats";

interface IProps {
  locations: IMapLocation[];
  selectedSite?: { latitude: number; longitude: number };
}

const containerStyle = {
  width: "100%",
  height: "550px",
  borderRadius: "8px",
  border: "1px solid #2F2A89",
  boxShadow: "0px 0px 4px 0px #00000040 inset",
};

const initialCenter = {
  lat: 6.9271,
  lng: 79.8612,
};

const GoogleMapDetails = (props: IProps) => {
  const { locations, selectedSite } = props;
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBQluIJBjDXSHfS9C5nNW24hw_i5uwE0Xg",
    libraries: ["places"],
  });

  const [mapCenter, setMapCenter] = useState(
    locations.length > 0 ? locations[0] : initialCenter
  );

  useEffect(() => {
    if (selectedSite) {
      setMapCenter({
        lat: selectedSite.latitude,
        lng: selectedSite.longitude,
      });
    }
  }, [selectedSite]);

  return (
    <>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={mapCenter}
          zoom={10}
        >
          {locations.map((location, index) => (
            <Marker key={index} position={location}>
              <InfoWindow position={location}>
                <div className="d-flex align-items-center gap-2">
                  <div className="text-dark font-size-14 font-weight-500">
                    {location.name}
                  </div>
                  <div>
                    <SiteStats value={50} />
                  </div>
                </div>
              </InfoWindow>
            </Marker>
          ))}
        </GoogleMap>
      )}
    </>
  );
};

export default GoogleMapDetails;
