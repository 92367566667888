import { EDistricts } from "../enums/districts";

const getDistrict = (): {
  value: EDistricts;
  label: string;
}[] => {
  const districts = [
    { value: EDistricts.COLOMBO, label: "Colombo" },
    {
      value: EDistricts.KANDY,
      label: "Kandy",
    },
    {
      value: EDistricts.GALLE,
      label: "Galle",
    },
    { value: EDistricts.AMPARA, label: "Ampara" },
    {
      value: EDistricts.ANURADHAPUARA,
      label: "Anuradhapura",
    },
    { value: EDistricts.BADULLA, label: "Badulla" },
    {
      value: EDistricts.BATTICALOA,
      label: "Batticaloa",
    },
    {
      value: EDistricts.GAMPAHA,
      label: "Gampaha",
    },
    {
      value: EDistricts.HAMBANTOTA,
      label: "Hambantota",
    },
    {
      value: EDistricts.JAFFNA,
      label: "Jaffna",
    },
    {
      value: EDistricts.KALUTARA,
      label: "Kalutara",
    },
    {
      value: EDistricts.KEGALLE,
      label: "Kegalle",
    },
    {
      value: EDistricts.KILINOCHCHI,
      label: "Kilinochchi",
    },
    {
      value: EDistricts.KURUNEGALA,
      label: "Kurunegala",
    },
    {
      value: EDistricts.MANNAR,
      label: "Mannar",
    },
    {
      value: EDistricts.MATALE,
      label: "Matale",
    },
    {
      value: EDistricts.MATARA,
      label: "Matara",
    },
    {
      value: EDistricts.MONERAGALA,
      label: "Moneragala",
    },
    {
      value: EDistricts.MULLATIVU,
      label: "Mullativu",
    },
    {
      value: EDistricts.NUWARA_ELIYA,
      label: "Nuwara Eliya",
    },
    {
      value: EDistricts.POLONNARUWA,
      label: "Polonnaruwa",
    },
    {
      value: EDistricts.PUTTALAM,
      label: "Puttalam",
    },
    {
      value: EDistricts.RATNAPURA,
      label: "Ratnapura",
    },
    {
      value: EDistricts.TRINCOMALEE,
      label: "Trincomalee",
    },
    {
      value: EDistricts.VAVUNIYA,
      label: "Vavuniya",
    },
  ];
  districts.sort((a, b) => a.label.localeCompare(b.label));
  return districts;
};

export default getDistrict;
