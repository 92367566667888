export const enum EDistricts {
  COLOMBO = "Colombo",
  KANDY = "Kandy",
  GALLE = "Galle",
  AMPARA = "Ampara",
  ANURADHAPUARA = "Anuradhapura",
  BADULLA = "Badulla",
  BATTICALOA = "Batticaloa",
  GAMPAHA = "Gampaha",
  HAMBANTOTA = "Hambantota",
  JAFFNA = "Jaffna",
  KALUTARA = "Kalutara",
  KEGALLE = "Kegalle",
  KILINOCHCHI = "Kilinochchi",
  KURUNEGALA = "Kurunegala",
  MANNAR = "Mannar",
  MATALE = "Matale",
  MATARA = "Matara",
  MONERAGALA = "Moneragala",
  MULLATIVU = "Mullativu",
  NUWARA_ELIYA = "Nuwara Eliya",
  POLONNARUWA = "Polonnaruwa",
  PUTTALAM = "Puttalam",
  RATNAPURA = "Ratnapura",
  TRINCOMALEE = "Trincomalee",
  VAVUNIYA = "Vavuniya",
}
